import { PublicClientApplication, type AccountInfo, type RedirectRequest } from '@azure/msal-browser'
import { APPLICATION_ID, TENANT_ID } from '@/utils/env'
import { reactive } from 'vue'
import { Configuration } from '@azure/msal-browser/lib/types'

const config: Configuration = {
  auth: {
    clientId: APPLICATION_ID,
    authority: `https://login.microsoftonline.com/${TENANT_ID}`,
    redirectUri: window.location.origin
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true
  }
}
export const graphScopes: RedirectRequest = {
  scopes: ['User.Read', 'profile']
}
export const state = reactive({
  isAuthenticated: false,
  user: null as AccountInfo | null
})
export const msalObj = new PublicClientApplication(config)
