import { render, staticRenderFns } from "./ValvePostLayoutComponent.vue?vue&type=template&id=482c7e02&scoped=true&"
import script from "./ValvePostLayoutComponent.vue?vue&type=script&setup=true&lang=ts&"
export * from "./ValvePostLayoutComponent.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./ValvePostLayoutComponent.vue?vue&type=style&index=0&id=482c7e02&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "482c7e02",
  null
  
)

export default component.exports