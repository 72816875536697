import Vue from 'vue'
import VueRouter from 'vue-router'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import router from './router'
import vuetify from './vuetify.config'
import i18n from './i18n.config'
import App from './App.vue'
import { createPinia, PiniaVuePlugin } from 'pinia'
import VueI18n from 'vue-i18n'
import '@fontsource/roboto/100.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import '@fontsource/roboto/900.css'
// don't forget to import CSS styles

import 'tiptap-vuetify/dist/main.css'
// Vuetify's CSS styles
import 'vuetify/dist/vuetify.min.css'
// use this package's plugin
Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'mdi'
})
Vue.use(PiniaVuePlugin)
Vue.use(VueRouter)
Vue.use(VueI18n)
const pinia = createPinia()
new Vue({
  router,
  pinia,
  vuetify,
  i18n,
  created () {
    console.log('%c🚀 Welcome to the SALT Tool of Kieback&Peter!', 'font-weight: bold')
  },
  render: h => h(App)
}).$mount('#app')
