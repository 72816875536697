import { ref } from 'vue'
import { msalObj, state, graphScopes } from '@/authentication/msalConfig'

export function useAuth () {
  const isAuthenticated = ref(false)
  const login = async () => {
    if (!msalObj) {
      throw new Error('MSAL Not initialized...')
    }
    await msalObj.loginRedirect(graphScopes)
    isAuthenticated.value = true
  }

  const logout = () => {
    if (!msalObj) {
      throw new Error('MSAL Not initialized...')
    }
    msalObj.logoutRedirect()
  }
  const handleRedirect = async () => {
    try {
      const authResult = await msalObj.handleRedirectPromise()

      if (authResult) {
        state.isAuthenticated = true
        state.user = authResult.account
      } else {
        // No new auth result? Check if a user is already signed in
        const accounts = msalObj.getAllAccounts()
        if (accounts.length > 0) {
          state.isAuthenticated = true
          state.user = accounts[0]
        } else {
          state.isAuthenticated = false
          state.user = null
        }
      }
      return msalObj.getAllAccounts().length > 0
    } catch (e) {
      return false
    }
  }
  return { isAuthenticated, login, logout, handleRedirect }
}
