import { APPLICATION_ID, BACKEND_URI } from '@/utils/env'
import { OpenAPI } from '@/__generated__'
import { graphScopes, msalObj, state } from '@/authentication/msalConfig'
export const acquireToken = async (scope: 'id' | 'auth' = 'id') => {
  // Have to check for user state
  if (state.user === null) {
    throw Error('State of Active Directory User is null!')
  }
  const response = await msalObj.acquireTokenSilent({
    account: state.user,
    scopes: graphScopes.scopes
  })
  if (scope === 'id') {
    return response.idToken // Return the access token
  } else if (scope === 'auth') {
    return response.accessToken
  }
  throw Error('Wrong auth scope was selected')
}

OpenAPI.TOKEN = async () => await acquireToken()
OpenAPI.BASE = BACKEND_URI

export * from './projects'
export * from './project-constructions'
export * from './function-list'
export * from './ddc'
export * from './hierarchy'
export * from './price'
export * from './asp'
export * from './macrolibrary'
export * from './nav'
export * from './services'
