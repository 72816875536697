import { defineStore } from 'pinia'
import { searchUser, getCurrentUser } from '@/api/users'
import { CancelablePromise, MyUser, MyUserDTO } from '@/__generated__'
import { acquireToken } from '@/api'
let requestForSearchUsers = undefined as CancelablePromise<Array<MyUser>> | undefined

interface UserGraphInfo {
    tags: []
}
interface UsersState {
    isLoggedIn: boolean,
    user?: MyUserDTO,
    userImage?: string,
    graphInfo?: UserGraphInfo,
    isConnectingToBackend: boolean,
    isConnectedToBackend: boolean,
    searchedUsers: MyUser[]
}

const initialState : UsersState = {
  isLoggedIn: false,
  user: undefined,
  graphInfo: undefined,
  isConnectingToBackend: true,
  isConnectedToBackend: false,
  searchedUsers: [],
  userImage: undefined
}

const useUserStore = defineStore('users', {
  state: () => {
    return initialState
  },
  getters: {
    getCurrentUserName: (state) => {
      return state.user ? `${state.user.firstName} ${state.user.lastName}` : undefined
    },
    getCurrentUserIsAdmin: (state) => {
      return state.user?.isAdmin || false
    },
    getCurrentUserInitials: (state) => {
      return state.user ? `${state.user.firstName[0]}${state.user.lastName[0]}` : undefined
    }
  },
  actions: {
    async fetchCurrentUser () {
      try {
        this.user = await getCurrentUser()
        this.isConnectedToBackend = true
        this.isLoggedIn = true
        return this.user
      } catch (e) {
        this.isConnectedToBackend = false
        this.isConnectingToBackend = false
        console.error(e)
        throw Error()
      }
    },
    async searchUsers (query: string) {
      await requestForSearchUsers?.cancel()
      requestForSearchUsers = searchUser(query)
      this.searchedUsers = await requestForSearchUsers
    },
    async logout () {
      this.user = undefined
    },
    async clearResults () {
      await requestForSearchUsers?.cancel()
      this.searchedUsers = []
    },
    setUserProfilePicture (url: string | undefined) {
      this.userImage = url
    }
  }
})

export { useUserStore, MyUserDTO }
