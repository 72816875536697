/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Item } from './Item';
import type { ProjectRole } from './ProjectRole';

export type ServiceData = {
  id?: number;
  item?: Item;
  pointType: ServiceData.pointType;
  shared: boolean;
  role: ProjectRole;
  seconds: number;
};

export namespace ServiceData {

  export enum pointType {
    BINARY_OUTPUT = 'BINARY_OUTPUT',
    ANALOG_OUTPUT = 'ANALOG_OUTPUT',
    BINARY_INPUT = 'BINARY_INPUT',
    ANALOG_INPUT = 'ANALOG_INPUT',
    COUNTER_INPUT = 'COUNTER_INPUT',
  }


}

